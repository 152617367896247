.prev,
.next {
    z-index: 10000;
    position: absolute;
    width: 4%;
    min-width: 20px;
    cursor: pointer;
    opacity: 1;
    transition: all .3s ease;
}

.prev.disabled, .next.disabled {
    cursor: default;
    opacity: .1;
}


.prev {
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
}

.next {
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
}
