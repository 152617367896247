.trail {
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    text-align: center;
}
.trail div {
    padding: 1rem 0;
    opacity: 0.3;
    transition: all 0.5s ease-in;
}
.trail div:hover {
    opacity: 0.6;
}
.trail .active {
    opacity: 1 !important;
}
